import React from "react"

const YellowBlob = () => {
  return (
    <svg
      width="100%"
      height="1227"
      viewBox="0 0 1436 1227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M0 339.599C0 339.599 204.478 263.668 430.496 238.835C706.852 208.472 1183.5 193.5 1305 112.424C1426.5 31.3486 1436 0.5 1436 0.5C1436 0.5 1436 796.07 1436 910.5C1249 1056.5 681.5 1045.42 462.5 1056.5C243.5 1067.58 0 1227 0 1227V339.599Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default YellowBlob
