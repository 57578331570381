import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import YellowBlob from "../components/yellow-blob"
// import SmallYellowBlob from "../components/small-yellow-blob"
import DarkBlob from "../components/dark-blob"
import YellowBg from "../components/yellow-bg"
import ClassCard from "../components/class-card"
import FinalCTA from "../components/final-cta"
import { CheckMark } from "../components/icons"
import Button from "../components/button"
// import SmartLink from "../components/smart-link"
import { Script } from "gatsby"
import TestimonialCard from "../components/testimonial-card"

export default function IndexPage({ data }) {
  return (
    <Layout>
      {/* HERO */}
      <Container>
        <div className="flex flex-col lg:flex-row justify-between mt-12 lg:mt-48 pb-12 px-4 items-center">
          <div className="order-2 ">
            <h1 className="text-5xl sm:text-7xl font-bold">
              Happy Dog. <br /> Happy Life.
            </h1>
            <p
              className="text-xl sm:text-2xl max-w-md	font-regular mt-3 sm:mt-4"
              // style={{ lineHeight: "42px" }}
            >
              Private dog training and online classes for Boise and the Treasure
              Valley
            </p>
          </div>
          <StaticImage
            src="../images/IMG_0482.jpg"
            className="z-10 rounded-lg order-1 lg:order-3 mb-6 "
            alt="Taylor and Scott smiling in front of a creek"
            layout="constrained"
            width={500}
          />
        </div>
      </Container>
      <Container style={{ zIndex: "100" }}>
        <div className="text-center z-10 relative mx-auto px-4 mb-32">
          <h2 className="mb-8">Dog Training that Works for You</h2>
          <p className="text-xl pb-2">You want the dog you envisioned...</p>
          <ul className="text-xl">
            <li className="flex items-center justify-center py-1">
              <div className="w-7">
                <CheckMark />
              </div>
              <span className="">Couch-cuddler</span>
            </li>
            <li className="flex items-center justify-center py-1">
              <div className="w-7">
                <CheckMark />
              </div>
              <span className="">Trailblazer</span>
            </li>
            <li className="flex items-center justify-center py-1">
              <div className="w-7">
                <CheckMark />
              </div>
              <span className="">Go-anywhere best friend</span>
            </li>
          </ul>
          <h2 className="mt-8 text-[28px] font-medium max-w-2xl mx-auto sm:mb-16 mb-8">
            Our online dog training classes help you build the foundation for
            your dream dog, all while seamlessly fitting into your busy
            schedule.
          </h2>
          <div className="mx-auto self-center w-fit">
            <Link to="/classes/">
              <Button>Enroll Now</Button>
            </Link>
          </div>
        </div>
        {/* <Link to={"/classes"} data-link-internal> */}

        {/* </Link> */}
      </Container>

      {/*Why choose Scotts school for dogs? */}
      <Container noMax>
        <div className="absolute -top-[10%] lg:-top-[50%] w-full z-0">
          <YellowBlob />
        </div>
        <div className="overflow-clip">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-top relative px-4 xl:px-0 mt-24 lg:mt-36  max-w-lg  lg:max-w-6xl mx-auto ">
            <div className="flex-1 max-w-2xl order-2 lg:basis-1/3 sm:mr-6">
              <h3>Online Classes</h3>
              <h2 className="">Why Online Classes?</h2>
              <h4 className="mb-6 lg:mb-8">
                Online dog training classes are becoming increasingly
                popular—and it&apos;s easy to see why.
              </h4>
              <h3 className="mb-0 font-crimson normal-case text-2xl tracking-normal text-lightBlack">
                Convenience and Flexibility
              </h3>
              <p className="mb-4 max-w-md">
                You can participate in a class from the comfort of your own
                home. You don&apos;t need to rush out the door after work or
                drag the kids along. In fact, you can cozy up in your sweats,
                turn on the Instant Pot, and train your dog while dinner&apos;s
                cooking
              </p>
              <h3 className="mb-0 font-crimson normal-case text-2xl tracking-normal text-lightBlack">
                A Great Learning Environment
              </h3>
              <p className="mb-4 max-w-md">
                Let&apos;s be honest, trying to listen to instructions while
                handling your squirmy dog is difficult. Dog training online
                allows for deeper discussions and focused conversations, so you
                can actually learn.
              </p>
              <h3 className="mb-0 font-crimson normal-case text-2xl tracking-normal text-lightBlack">
                Works for All Dogs
              </h3>
              <p className="mb-8 max-w-md">
                Online dog training is a great option for dogs that are
                reactive, anxious, or uncomfortable in new settings. But even
                the calmest of dogs benefit from learning new skills in
                familiar, distraction-free environments.
              </p>
            </div>

            <div className="flex-1 order-1 lg:order-last lg:basis-2/3 w-full max-w-2xl lg:max-w-5xl self-start pt-0 lg:pt-12  xl:pl-16">
              {/* <StaticImage
              src="../images/IMG_0619.jpg"
              className="mt-8 rounded-lg"
              alt="Taylor helps a client train their dog in Boise"
            /> */}
              <Script
                src="https://fast.wistia.com/embed/medias/1xarlog4nj.jsonp"
                async
              ></Script>
              <Script
                src="https://fast.wistia.com/assets/external/E-v1.js"
                async
              ></Script>
              <div
                className="wistia_responsive_padding"
                style={{ padding: "56.25% 0 0 0", position: "relative" }}
              >
                <div
                  className="wistia_responsive_wrapper"
                  style={{
                    height: "100%",
                    left: 0,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <div
                    className="wistia_embed wistia_async_1xarlog4nj videoFoam=true"
                    style={{
                      height: "100%",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div
                      className="wistia_swatch"
                      style={{
                        height: "100%",
                        left: 0,
                        opacity: 0,
                        overflow: "hidden",
                        position: "absolute",
                        top: 0,
                        transition: "opacity 200ms",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/1xarlog4nj/swatch"
                        style={{
                          filter: "blur(5px)",
                          height: "100%",
                          objectFit: "contain",
                          width: "100%",
                        }}
                        alt=""
                        aria-hidden="true"
                        onLoad={event =>
                          (event.target.parentNode.style.opacity = 1)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <aside className="mt-2 lg:mt-8 mb-12 font-crimson  normal-case text-2xl sm:text-3xl	tracking-normal max-w-lg">
                Dog training can and should be fun!
              </aside>
            </div>
          </div>
        </div>
      </Container>

      {/* Services Section */}
      <Container noMax>
        <div
          className="flex flex-col items-center relative pt-24 pb-8 max-w-6xl mx-auto"
          style={{ zIndex: 20 }}
        >
          <div className="text-center">
            <h2 className=" mb-4">Virtual Dog Training Classes</h2>
            <h3 className="mb-0 normal-case text-2xl tracking-normal font-normal text-lightBlack">
              See our latest offerings
            </h3>
          </div>
          <div className="px-4">
            {data.classes2.edges.map(({ node }, index) => (
              <ClassCard
                title={node.data.title.text}
                description={node.data.description.text}
                benefits={node.data.description_benefits.richText.map(
                  bene => bene.text
                )}
                slug={node.url}
                featured_image={node.data.featured_image.gatsbyImageData}
                key={index + 200}
                index={index + 200}
                price={`$${node.data.price}`}
              />
            ))}
            {/* {data.classes.edges.map(({ node: { frontmatter } }, index) =>
              frontmatter.is_active ? (
                <ClassCard
                  title={frontmatter.title}
                  starting_at_price={frontmatter.starting_at_price}
                  plain_label={frontmatter.plain_label}
                  description={frontmatter.description}
                  benefits={frontmatter.benefits.map(bene => bene.benefit)}
                  slug={`/classes/${frontmatter.slug}`}
                  featured_image={frontmatter.featured_image}
                  key={index}
                  index={index}
                />
              ) : undefined
            )} */}
          </div>
        </div>
      </Container>

      {/* Dark Video Section */}
      <Container noMax>
        <div
          className="absolute -top-[20%] lg:-top-[40%] w-full h-[130%] lg:h-[170%]"
          style={{ zIndex: 11 }}
        >
          <DarkBlob />
        </div>
        <div
          className="flex flex-col lg:flex-row justify-between items-center lg:items-start relative mt-24 sm:mt-64 max-w-6xl mx-auto"
          style={{ zIndex: 20 }}
        >
          <div className="flex-1 lg:basis-1/3 text-lessLightGrey px-4 xl:px-0">
            <h2 className="text-primary mb-2">Our Training Methods</h2>
            <p className="max-w-sm text-lessLightGrey mb-8">
              With our positive reinforcement-based dog training methodology,
              you will…
            </p>
            <h3 className="mb-0 font-crimson text-lightGrey normal-case text-2xl tracking-normal		">
              Enjoy a well-behaved dog
            </h3>
            <p className="mb-8 max-w-sm text-lessLightGrey">
              Our dog training techniques help your dog learn reliable behaviors
              they will know for the rest of their lives. If you put the time in
              now, you&apos;ll be rewarded in dividends.
            </p>
            <h3 className="mb-0 font-crimson text-lightGrey normal-case text-2xl tracking-normal	">
              Be able to train with the whole family
            </h3>
            <p className="mb-8  max-w-sm text-lessLightGrey">
              Anyone, including young children, can learn how to train with
              positive reinforcement. And you&apos;ll have fun doing it!
            </p>
            <h3 className="mb-0 font-crimson text-lightGrey normal-case text-2xl	tracking-normal">
              See results quickly
            </h3>
            <p className="mb-4 max-w-sm text-lessLightGrey">
              Once your dog gets the training “game,” you&apos;ll be astonished
              by how quickly they can learn behaviors and replace bad habits.
            </p>
          </div>
          <div className="flex-1 lg:basis-2/3 w-full max-w-2xl lg:max-w-5xl pt-4 lg:pt-0 px-4 xl:pl-16">
            {/* <StaticImage
              src="../images/IMG_0619.jpg"
              className="mt-8 rounded-lg"
              alt="Taylor helps a client train their dog in Boise"
            /> */}
            <StaticImage
              src="../images/IMG_0517.jpg"
              className="rounded-lg"
              alt="Taylor and Scott high fiving after some great dog training in Boise"
              width={700}
            />

            <aside className="mt-2 lg:mt-8 font-crimson text-lightGrey normal-case text-2xl sm:text-3xl	tracking-normal ">
              See lasting results with positive reinforcement dog training
            </aside>
          </div>
        </div>
      </Container>

      {/* About Taylor */}
      <Container noMax>
        <div className="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start relative lg:mt-64 max-w-6xl mx-auto">
          <div className="flex-1 relative lg:ml-36 mb-24 mt-36 lg:mt-0">
            <StaticImage
              src="../images/IMG_0231-1.jpg"
              alt="dog training with mia at home in Boise, Idaho"
              className="rounded-lg"
              height={500}
            />
            <StaticImage
              src="../images/cute-scott.png"
              className="-bottom-5 lg:-bottom-16 -left-2 lg:-left-20 rounded-lg"
              style={{ position: "absolute" }}
              alt="Scott and Taylor sitting on their porch when he was first adopted"
            />
          </div>
          <div className="flex-1  max-w-lg px-4 lg:px-0">
            <h3>Meet Your Trainer</h3>
            <h2>Taylor Wyllie, KPA CTP</h2>
            <p className="mb-4">
              Taylor Wyllie is a graduate of the Karen Pryor Academy for
              Professional Dog Trainers, and a member of the Association of
              Professional Dog Trainers.
            </p>
            <p className="mb-4">
              Her dog training journey began when she adopted her dog, Scott,
              and realized days later that he was a bit of an anxious mess
              (barking, lunging, the whole nine yards). She dove into the world
              of positive reinforcement dog training, helping to teach Scott how
              to relax and be a “normal” dog.
            </p>
            <p className="mb-4">
              Taylor has since graduated from the prestigious Karen Pryor
              Academy, founded the Boise Reactive Dog Owner group, worked with a
              Treasure Valley shelter, and helped to make dogs and their humans
              happier.
            </p>
            <p>She hopes to help you and your dog too.</p>
          </div>
        </div>
      </Container>

      {/* Services Section */}
      {/* <Container noMax>
        <div
          className="absolute top-[5%] sm:top-[2%] w-full h-[90%]"
          style={{ zIndex: 10 }}
        >
          <YellowBg />
        </div>
        <div
          className="flex flex-col items-center relative pt-36 pb-8 max-w-6xl mx-auto"
          style={{ zIndex: 20 }}
        >
          <div className="text-center">
            <h2 className=" mb-4">Private Dog Training</h2>
            <h3 className="mb-0 normal-case text-2xl tracking-normal font-normal text-lightBlack">
              All our services are in your home or virtual
            </h3>
          </div>
          <div className="px-4">
            {data.services.edges.map(({ node: { frontmatter } }, index) =>
              frontmatter.is_active ? (
                <ClassCard
                  title={frontmatter.title}
                  starting_at_price={frontmatter.starting_at_price}
                  plain_label={frontmatter.plain_label}
                  description={frontmatter.description}
                  benefits={frontmatter.benefits.map(bene => bene.benefit)}
                  slug={`/services/${frontmatter.slug}`}
                  featured_image={frontmatter.featured_image}
                  key={index}
                  index={index}
                />
              ) : undefined
            )}
          </div>
        </div>
      </Container> */}

      {/* Why you should sign up section */}
      <Container>
        <div className="flex flex-col lg:flex-row items-center lg:pt-32 mb-12 lg:mb-32 px-4 xl:px-0">
          <div className="flex-1 flex flex-col">
            <aside className="order-2 font-crimson normal-case text-4xl	tracking-normal max-w-md lg:max-w-lg font-light mb-24 lg:mb-0">
              Positive reinforcement focuses on teaching your dog what to do,
              rather than what not to do.
            </aside>
            <div className="mx-auto order-1 lg:order-last lg:ml-24 mb-6 lg:mb-0 rounded-full">
              <StaticImage
                src="../images/IMG_0405.jpg"
                width={275}
                height={275}
                className="mt-8 rounded-full"
                transformOptions={{ cropFocus: "center" }}
                alt="Scott looking at the camera smiling"
              />
            </div>
          </div>
          <div className="flex-1 max-w-md">
            <h2 className="mb-8 lg:mb-12 font-normal  ">
              Why you should sign up for online dog training
            </h2>
            <ul>
              <li className="border-b border-primary pb-3 mb-3 lg:pb-5 lg:mb-5">
                Help your dog behave and be happier (which will help you be
                happier!)
              </li>
              <li className="border-b border-primary pb-3 mb-3 lg:pb-5 lg:mb-5">
                Learn how to train in a way that&apos;s fun for both you and
                your dog
              </li>
              <li className="border-b border-primary pb-3 mb-3 lg:pb-5 lg:mb-5">
                Enjoy a well-trained dog for the rest of their life
              </li>
              <li className=" pb-5 mb-5y">
                Feel empowered with the tools and knowledge to solve future
                problems
              </li>
            </ul>
          </div>
        </div>
      </Container>

      {/* Pull Quote */}
      <Container noMax>
        <div className="flex items-center justify-center py-20 bg-lightestGray">
          <aside className="font-crimson normal-case text-2xl sm:text-4xl	tracking-normal max-w-lg font-light text-center">
            “You&apos;ll feel like a dog-whisperer after learning how to
            understand your dog.”
          </aside>
        </div>
      </Container>

      {/* Testimonials */}
      <Container>
        <div className="text-center  py-20 ">
          <h2 className="">What others are saying...</h2>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center">
          {data.tests.edges.map(({ node }, index) => (
            <TestimonialCard data={node.frontmatter} key={index} />
          ))}
        </div>
      </Container>
      {/*Certificication matters */}
      <Container noMax>
        <div className=" overflow-visible	flex flex-col lg:flex-row justify-between items-top lg:items-top relative px-4 xl:px-0 mt-24 lg:mt-56 lg:mb-32  max-w-lg  lg:max-w-6xl mx-auto ">
          <div className="hidden lg:block absolute h-[1100px] w-[1100px] bg-lightestGray rounded-full	 -top-[150px] -left-[400px]"></div>
          <div className="flex-1 max-w-lg relative">
            <h2 className="mb-6 lg:mb-8">Certification Matters</h2>

            <p className="mb-4 max-w-md">
              Anyone can call themselves a dog trainer, there are no state or
              federal licensing requirements.
            </p>

            <p className="mb-4 max-w-md">
              However, there are several internationally recognized
              certification organizations. The Karen Pryor Academy, where Taylor
              is certified, is one of the top organizations in the country and,
              even, the world.
            </p>

            <p className="mb-8 max-w-md">
              KPA graduates have completed an extensive educational program and
              have passed detailed assessments of both technical knowledge and
              hands-on skills.
            </p>
            <p className="mb-8 max-w-md underline">
              <a
                href="https://karenpryoracademy.com/ctp/choose-kpa-ctp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </p>
          </div>

          <div className="relative order-1 pb-6 lg:order-last">
            <StaticImage
              src="../images/kpa-badge-ctp.png"
              className="rounded-lg"
              alt="Taylor and Scott high fiving after some great dog training in Boise"
              // width={600}
            />
          </div>
        </div>
      </Container>
      {/* Final CTA */}
      <FinalCTA />
    </Layout>
  )
}

export const Head = () => <Seo />

export const query = graphql`
  {
    classes: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//classes//" }
        frontmatter: { is_class: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            is_active
            slug
            description
            plain_label
            benefits {
              benefit
            }
            starting_at_price
          }
        }
      }
    }
    classes2: allPrismicClass(sort: { fields: data___group }) {
      edges {
        node {
          url
          data {
            group
            title {
              text
            }
            description {
              text
            }
            description_benefits {
              richText
            }
            price
            featured_image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//classes//" }
        frontmatter: { is_class: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            is_active
            slug
            description
            plain_label
            benefits {
              benefit
            }
            starting_at_price
          }
        }
      }
    }
    tests: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//testimonials//" } }
    ) {
      edges {
        node {
          frontmatter {
            quote
            name
            service
          }
        }
      }
    }
  }
`
