import React from "react"
import PropTypes from "prop-types"
import Button from "./button"
import { CheckMark } from "./icons"
import SmartLink from "./smart-link"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ClassCard = ({
  title,
  featured_image,
  starting_at_price,
  price,
  showIncludes,
  plain_label,
  description,
  benefits,
  note,
  showInitialConWarning,
  disableButton,
  buttontext = "Learn More & Sign Up",
  slug,
  bookingLinks,
  index,
}) => {
  const image = getImage(featured_image)

  return (
    <div className="my-14 flex justify-center">
      {image && (
        <div
          className={`hidden lg:block rounded-lg basis-1/4 pt-12 	${
            index % 2 !== 0 ? "order-1" : "order-3"
          }`}
        >
          <GatsbyImage
            image={image}
            // width={"100%"}
            layout={"fullWidth"}
            className={`rounded-lg 	${
              index % 2 === 0 ? "rotate-6" : "-rotate-6"
            }`}
            alt={`Headshot of happy client`}
          />
        </div>
      )}

      <div
        className="flex flex-col rounded-2xl bg-white p-6 md:p-10 max-w-xl text-lightBlack order-2 "
        style={{ filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15))" }}
      >
        <h4 className="font-crimson text-3xl font-semibold mb-1">{title}</h4>
        {price && <p className="w-fit text-lg mb-6">{price}</p>}
        {plain_label && (
          <p className="italic w-fit text-md mb-6 text-gray-500">
            {plain_label}
          </p>
        )}
        {description && <p className="mb-6">{description}</p>}
        {showIncludes && <p className="pb-1">Includes:</p>}
        <ul className="mb-8">
          {benefits.map(benefit => (
            <li className="flex items-top pb-2  " key={benefit}>
              <div className="mt-1 w-7">
                <CheckMark />
              </div>
              <span className="flex-1">{benefit}</span>
            </li>
          ))}
        </ul>
        {note && <p className="mb-14 text-gray-500">{note}</p>}
        {!price && (
          <p className="mb-6">
            Starting at:
            <br /> <span className="text-xl">${starting_at_price}</span>
          </p>
        )}
        {!bookingLinks && (
          <SmartLink href={slug} className={"mx-auto"}>
            <Button
              disabled={disableButton && showInitialConWarning}
              // onClick={() => navigate(`/services/${slug}`)}
              style={{ width: "fit-content", margin: "0px auto" }}
            >
              {buttontext}
            </Button>
          </SmartLink>
        )}
        {bookingLinks &&
          bookingLinks.map(link => (
            <div className="mb-4 mx-auto" key={link.button_label}>
              <SmartLink href={link.link} className={"mx-auto"}>
                <Button
                  disabled={disableButton && showInitialConWarning}
                  // onClick={() => navigate(`/services/${slug}`)}
                  style={{ width: "fit-content", margin: "0px auto" }}
                >
                  {link.button_label}
                </Button>
              </SmartLink>
            </div>
          ))}
        {showInitialConWarning && (
          <p className="mt-10 text-gray-500">
            * All clients have to start with an online class. If requested, upon
            graduation your trainer will send you a link to purchase the right
            package for you
          </p>
        )}
      </div>
    </div>
  )
}

ClassCard.propTypes = {
  title: PropTypes.string.isRequired,
  // The italic label that explains it simply
  plain_label: PropTypes.string,
  description: PropTypes.string,
  benefits: PropTypes.array,
  starting_at_price: PropTypes.string,
  // Lists an exact price, not to be used with starting_at_price
  price: PropTypes.string,
  showIncludes: PropTypes.bool,
  note: PropTypes.string,
  // the asterisk at the bottom of the card
  showInitialConWarning: PropTypes.bool,
  slug: PropTypes.string.isRequired,
}

export default ClassCard
